@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap");
body {
	font-family: "DM Sans", sans-serif;
}
#mainView{
	min-height: 1080px;
}

option {
	color: black;
}

#erroMassage{
	color: red;
}

#card-width{
	width: 65%;
}

#checkTable{
	width: 100%;
	height: 75vh;
	min-height: 820px;
	max-height: 850px;
}
#checkTableH{
	height: 100%;
	/* background-color: black; */

}

#Charts{
height: 400px;
}

#tbody, #ubody , #rbody{
	max-height: 650px;
	/* padding-top: 5px; */
}
#devbody{
	margin-top: 16px;
	margin-bottom: 16px;
	height: 36px;
	position: absolute;
	bottom: 10px;
	display: flex;
	color: blue;
}
#dbody{
	margin-top: 10px;
	margin-bottom: 20px;
}
#trhight{
	height: 40px;
}
#TR{
	align-self:center ;
	margin:5px;
}
.usersTR{
	width: 32px;
	height: 32px;
}
#popup {
	display: flex;
	flex-direction: column;
	width: 400px;
	background-color: rgb(244 247 254);
	position: fixed;
	z-index: 100;
	padding: 20px;
	border-radius: 20px;
	top: 40%;
	left: 50%;
	transform: translate(-50%, -50%);
	/* Additional styling */
  }
  #popIcon{
	align-self: flex-end;
	width: 20px;
	height: 20px;

  }
  #addStatistic{
	margin-right: 20px;
  }
  #label{
	color: black;
  }
  .ant-btn-primary {
	color: black;
	border: 1px solid gray;
	
}
#delPopup {
	display: flex;
	flex-direction: column;
	width: 320px;
	height: 160px;
	background-color: rgb(244 247 254);
	position: fixed;
	padding: 15px;
	border-radius: 10px;
	top: 40%;
	left: 50%;
	transform: translate(-50%, -50%);

}
#delText{
	color: red;
	margin-top: 10px;
}
#yesno{
	display: flex;
	flex-direction: row;
	margin-top: 30px;
	align-self: flex-end;

}
.yesorno{
	border: 1px solid gray;
	width: 50px;
	border-radius: 5px;
	margin-right: 10px;
	color: black;
	background-color: white;

}
.yesorno:hover{
	background-color: rgb(66 42 251 / var(--tw-bg-opacity));
	color: white;
}

.welcomeBtn{
	border: 1px solid #e9ecef;
	width: 300px;
	height: 40px;
	text-align: center;
	margin-bottom: 15px;
	border-radius: 10px;
	color: white;
	background-color: rgb(147, 172, 233);
}

.filterTableDrop{
	border: 1px solid #e9ecef;
	width: 200px;
	height: 35px;
	padding-inline: 5px;
	margin-left: 15px;
	border-radius: 5px;
	color: black;
	background-color: rgb(244 247 254);
	font-size: 16px;
}

.filterBtn{
	border: 1px solid #e9ecef;
    width: 80px;
	height: 35px;
	margin-left: 15px;
	border-radius: 10px;
	color: black;
	background-color: rgb(244 247 254);
	/* color: blue; */

}

/* The Modal (background) */
.modal {
    /* display: none; */
    position: fixed;
    z-index: 100;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.4);
}
#usersTable, #reqsTable{
	width: 100%;
	height: 75vh;
	margin-top: 20px;
	min-height: 820px;
	max-height: 850px;
	overflow: hidden;

}
.ProgressSat{
	display: flex;
	flex-direction: row;
	align-items: center;
}
.ProgressP{
	margin-left: 5px;
	width: 55px;
}

/* #dateColorG{
	color: green;
}
#dateColorR{
	color: red;

} */
 .needHelp{
	background-color: none;
	/* border: 1px solid rgb(136, 90, 205); */
	/* border-radius: 5px; */
	/* width: 450px; */
	height: 35px;
	font-weight: bold;
	/* margin: 0 20px; */
	/* padding: 5px;  */
	/* float: right; */
	/* margin-left: auto; */
 }
 .needHelp button{
	color: blue;
	border: none;
 }

 /* #helpUp{
	display: flex;
	flex-direction: column;
	width: 320px;
	height: 150px;
	background-color: rgb(233, 233, 233);
	position: fixed;
	padding: 15px;
	border-radius: 10px;
	top: 40%;
	left: 50%;
	transform: translate(-50%, -50%);
	border: 1px solid blueviolet;
} */
#helpText{
	color: black;
	margin-top: 10px;
	font-weight:500;
}
.statusCh{
	cursor: pointer;
}
.filterDev{
    display: flex;
    justify-content: center;
}
.MDinpMag{
	width: 80px;
}
.hoverEdit:hover{
	color: rgb(66 42 251 / var(--tw-bg-opacity));
}

@keyframes slide {
	0% { transform: translateX(0); }
  100% { transform: translateX(calc(-147px * 30)); }
  }
  
.webLogos{
	overflow: hidden;
	margin-bottom: 30px;
	margin-top: 10px;
}
.logos-slide{
	display: flex;
	width: calc(147px * 28);
	flex-direction: row;
	justify-content: space-between;
	align-self: center;
	animation: 30s slide infinite linear;
}
.logoImg{
	width: 147px;
	height: 103px;
	margin-right: 10px;
}
.webLogos:hover .logos-slide {
	animation-play-state: paused;
  }
  /* .webLogos:before,
  .webLogos:after {
	position: absolute;
	top: 0;
	width: 250px;
	height: 100%;
	content: "";
	z-index: 2;
  } */

  /* .webLogos:after {
	right: 0;
	background: linear-gradient(to right, rgba(255, 255, 255, 0), white);
  }
  
.webLogos:before {
	left: 0;
	background: linear-gradient(to left, rgba(255, 255, 255, 0), white);
  } */
.dropdownErro{
	margin-left: -35px;
  }
.dashLogo{
	/* margin-top: -100px; */
	/* width: 200px; */
	/* margin-left: -30px; */
	width: 100%;
	height: 100%;
	object-fit: cover;
  }

.devdashLogo{
	width: 300px;
	height: 150px;
	}

.bottomDev{
margin-top: 10px;
padding: 0 12px 12px;
display: flex;
flex-direction: column;
}	
.rightSide{
	background-color: rgb(244 247 254 / var(--tw-bg-opacity));
}
/* .rightSideChild{
	margin-bottom: 20px;
	max-height: 750px;
} */
@keyframes ani {
	0% {
	  height: 0;
	  width: 0;
	  opacity: 0;
	}
	99% {
	  height: 0;
	  width: 0;
	  opacity: 0;
	}
	100% {
	  height: auto;
	  width: auto;
	  opacity: 1;
	}
  }

.noDataFound{
    margin: 5px 0;
	width: 160px;
	color: #d80000;
	font-size: 18px;
	font-weight: bold;
	animation: ani .05s linear;
}

.loader {
	margin-top: 15px;
	border: 5px solid #f3f3f3; /* Light grey */
	border-top: 5px solid #3498db; /* Blue */
	border-radius: 50%;
	width: 30px;
	height: 30px;
	animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
	0% { transform: rotate(0deg); }
	100% { transform: rotate(360deg); }
  }

  
@media only screen and (max-device-width: 1600px) {
	.MDchecktable{
overflow-x: scroll;	
max-height: 750px;
}
	
}
	
@media only screen and (max-device-width: 900px) {
.filterDev{ 
      display:inline;
}

.filterBtn{
	margin-top: 5px;
	margin-left: 5px;
}
.MDtitle{
	margin-right: 10px;
}
.filterTableDrop{
	margin-left: 5px;
}	
/* .filterBtn{
	margin-top: 5px;
	font-size: 16px;
	margin-left: 5px;
}
.filterTableDrop{
	margin-top: 5px;
	width: 165px ;
	font-size: 14px;
	margin-left: 5px;
}	 */
.bottomDev{
	margin-top: 20px;
	}
}
@media only screen and (max-device-width: 825px) {

	.bottomDev{
	margin-top: 50px;
	}			
	}
		
@media only screen and (max-device-width: 760px) {

.bottomDev{
margin-top: 50px;
}			
}
	
@media only screen and (max-device-width: 720px) {
body{
	min-width: 420px;
}
.MDtitle{ 
	font-size: 18px;
	width: 70px;
	margin-right: 10px;
}
.filterBtn{
	margin-top: 5px;
	font-size: 16px;
	margin-left: 5px;
}
.filterTableDrop{
	margin-top: 5px;
	width: 165px ;
	font-size: 14px;
	margin-left: 5px;
}
.MDpageSize{
display: none;
}
.MDbtnmag{
	width: 28px;
	height: 28px;
	padding: 2px;
}
#devbody{
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
}
.MDpageMag{
height: fit-content;
padding: 0px;
}
.MDinpMag{
width: 40px;
height: 28px;
font-size: 14px;
}
.MDaddStatistic{
	height: 35px;
	width: 35px;
}
#popup{
	width: 350px;
	top: 50%;
	left: 50%;
}
/* .MDfooter{
	display:none ;
} */
.MDchecktable{
	padding-bottom: 50px;
	height: 720px;
}
.bottomDev{
	margin-top: 25px;
   }	
 }
 @media only screen and (max-device-width: 540px) {
	.bottomDev{
	 margin-top: 60px;
	}
	.needHelp{
     font-size: 14px;	 }
										
	}
	
	@media only screen and (max-device-width: 500px) {
		.needHelp{
		 margin-top: 10px;
		}									
		}
		